<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <router-link :to="{ name: 'autoskola-obrasci-ugovora-kreiraj' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t("KreirajObrazac") }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Obrasci')">
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-obrasci-ugovora-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="modalBrisanje(data.item.id, `${data.item.naziv}`)"
                >
                  {{ $t("Obriši") }}
                </b-button>
              </b-button-group>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaKreiranihObrazaca') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-brisanje-obrasca"
      ref="modal-brisanje-obrasca"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Obrisi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('ObrisiObrazac')"
      size="lg"
      @ok="obrisiObrazac"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigurniDaZeliteDaObriseteObrazac:') }}
        </p>
        <p>
          {{ podaciObrascaZaBrisanje.naziv }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="error-modal"
      :title="$t('Greska')"
      header-bg-variant="danger"
      ok-only
    >
      <p class="my-4">
        {{ $t(errorMessage) }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCardBody,
  BButtonGroup,
  BCard,
  BAlert,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BButtonGroup,
    BCard,
    BAlert,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'naziv', label: this.$i18n.t('Naziv') },
        { key: 'opis', label: this.$i18n.t('Opis') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      podaciObrascaZaBrisanje: {},
      errorMessage: '',
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/obrasci-ugovora').then(response => {
        this.items = response.data
      })
    },
    modalBrisanje(id, naziv) {
      this.podaciObrascaZaBrisanje = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-brisanje-obrasca')
    },
    obrisiObrazac() {
      this.$http.delete(`/autoskole-administratori/obrasci-ugovora/${this.podaciObrascaZaBrisanje.id}`).then(() => {
        this.$bvModal.hide('modal-brisanje-obrasca')
        this.pokupiListu()
      })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorMessage = error.response.data.message
            this.$bvModal.show('error-modal')
          }
        })
    },
  },
}
</script>
